<template>
  <div class="terms">
    <section class="is-white has-text-left m-v-l">
      <div class="container">
        <div class="columns">
          <div class="column is-8">
            <heading>Terms and Conditions</heading>
            <p>
              Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
              Hype.News application (the "Service") operated by Hypefactors (“Hype.News”, “Hypefactors”, "us", "we", or
              "our").
            </p>
            <p>
              These Terms and conditions govern your access to and use of the services, websites, and applications
              offered by Hype.News/Hypefactors. Your access to and use of the Service is conditioned on your acceptance
              of and compliance with these Terms. By accessing or using the Service you agree to be bound by these
              Terms.
            </p>
            <br>
            <h4>The Hype.News Service</h4>
            <p>
              The Hype.News service is a sub service of Hypefactors. The Service includes publishing and distribution
              of media content, and it includes searching and monitoring for media content. Companies, organizations,
              state authorities and other sources can make their media content available for journalists and other
              stakeholders through Hype.News. Journalists and other users can use the Hype.News service to discover
              media content, monitor specific topics, industries and organizations and subscribe to those monitorings
              thus getting notifications.
            </p>
            <br>
            <h4>Use of the Service</h4>
            <p>
              You may use the Service only if you can form a binding contract with Hype.News, and only in compliance
              with these Terms and all applicable national and international laws, rules and regulations. You must hold
              the right to represent the organization from which you will publish news stories, and you must provide us
              accurate information, including your real name, when you create your account on Hype.News.
            </p>
            <p>
              We may, without prior notice, change the Service; stop providing the Service or features of the Service,
              to you or to users generally; or create usage limits for the Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to access the Service and for any
              activities or actions under your password. We encourage you to use "strong" passwords (that use a
              combination of upper and lower case letters, numbers and symbols) with your account. You agree not to
              disclose your password to any third party. You must notify us immediately upon becoming aware of any
              breach of security or unauthorized use of your account. Hype.News will not be liable for any loss or
              damage arising from your failure to comply with this instruction.
            </p>
            <br>
            <h4>Use of the Service</h4>
            <p>
              You may use the Service only in compliance with these Terms and all applicable national and international
              laws, rules and regulations. You must hold the right to represent the organization from which you will
              publish news stories, and you must provide us accurate information, including your real name, when you
              create your account on Hype.News.
            </p>
            <p>
              We may, without prior notice, change the Service; stop providing the Service or features of the Service,
              to you or to users generally; or create usage limits for the Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to access the Service and for any
              activities or actions under your password. We encourage you to use "strong" passwords
              (that use a combination of upper and lower case letters, numbers and symbols) with your account.
              You agree not to disclose your password to any third party. You must notify us immediately upon becoming
              aware of any breach of security or unauthorized use of your account. Hype.News will not be liable for any
              loss or damage arising from your failure to comply with this instruction.
            </p>
            <br>
            <h4>User Content</h4>
            <p>
              "Content" means any information, text, graphics, or other materials uploaded, downloaded or appearing on
              the Service. You retain ownership of all Content you submit, post, display, or otherwise make available on
              the Service.
            </p>
            <br>
            <h4>Your License to Hype.News</h4>
            <p>
              By submitting, posting or displaying Content on or through the Service, you grant us a worldwide,
              non-exclusive, royalty-free license to use, copy, reproduce, process, create derivative works from,
              publish, transmit, display and distribute such Content in any and all media or distribution methods (now
              known or later developed).
            </p>
            <p>
              You agree that this license includes the right for other users of the Service to make your Content
              available to others for the publication, distribution, syndication, or broadcast of such Content on other
              media and services, subject to our terms and conditions for such Content use. Such additional uses by
              Hype.News or others may be made with no compensation paid to you with respect to the Content that you
              submit, post, transmit or otherwise make available through the Service.
            </p>
            <p>
              We may modify, adapt, or create derivative works from your Content in order to transmit, display or
              distribute it over computer networks and in various media and/or make changes to your Content as are
              necessary to conform and adapt that Content to any requirements or limitations of any networks, devices,
              services or media.
            </p>
            <p>
              Additionally, by uploading content to the site, you warrant, represent and agree that you have the right
              to grant Hype.News the license described above. You also represent, warrant and agree that you have not
              and will not contribute any Content that (a) infringes, violates or otherwise interferes with any
              copyright or trademark of another party, (b) reveals any trade secret, unless the trade secret belongs to
              you or you have the owner's permission to disclose it, (c) infringes any intellectual property right of
              another or the privacy or publicity rights of another, (d) is libelous, defamatory, abusive, threatening,
              harassing, hateful, offensive or otherwise violates any law or right of any third party, (e) creates an
              impression that you know is incorrect, misleading, or deceptive, including by impersonating others or
              otherwise misrepresenting your affiliation with a person or entity; (f) contains other people's private or
              personal information without their express authorization and permission, and/or (g) contains or links to a
              virus, trojan horse, worm, time bomb or other computer programming routine or engine that is intended to
              damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or
              information. Hype.News reserves the right in its discretion to remove any Content from the Site, suspend
              or terminate your account at any time, or pursue any other remedy or relief available under equity or
              law.
            </p>
            <br>
            <h4>Hype.News’ Licenses to You</h4>
            <p>
              Subject to these Terms, Hype.News gives you a worldwide, royalty-free, revokable, non-assignable and
              non-exclusive license to re-post any of the Content on Hype.News anywhere on the rest of the web provided
              that that you: (a) do not modify the Content; (b) attribute Hype.News by name in readable text and with a
              human and machine-followable link (an HTML &lt;a&gt; anchor tag) linking back to the page displaying the
              original source of the content on <a
                href="http://hype.news"
              >http://hype.news</a> on every page that contains Hype.News content; (c) upon
              request, either by Hype.News or by a user who contributed to the Content, make a reasonable effort to
              update a particular piece of Content to the latest version on <a
                href="http://hype.news"
              >http://hype.news</a>.
            </p>
            <p>
              Your Content will be viewable by other users of the Service and through third party services and
              websites. You should only provide Content that you are comfortable sharing with others under these
              Terms.
            </p>
            <p>
              All Content, whether publicly posted or privately transmitted, is the sole responsibility of the
              organization who originated such Content. We may not monitor or control the Content posted via the
              Service. Any use of or reliance on any Content or materials posted via the Service or obtained by you
              through the Service is at your own risk. We do not endorse, support, represent or guarantee the
              completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the
              Service or endorse any opinions expressed via the Service. You understand that by using the Service, you
              may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate. Under
              no circumstances will Hype.News be liable in any way for any Content, including, but not limited to, any
              errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of
              any Content made available via the Service or broadcast elsewhere.
            </p>
            <p>
              You are responsible for your use of the Service, for any Content you provide, and for any consequences
              thereof, including the use of your Content by other users and third party partners. You understand that
              your Content may be republished and if you do not have the right to submit Content for such use, it may
              subject you to liability. Hype.News will not be responsible or liable for any use of your Content by
              Hype.News in accordance with these Terms.
            </p>
            <p>
              We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any
              Content on the Service and to terminate users or reclaim usernames. We also reserve the right to access,
              read, preserve, and disclose any information if we believed in good faith that it is reasonably necessary
              to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce or
              administer our agreements with users (such as these Terms), including investigation of potential
              violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv)
              respond to user support requests, or (v) protect the rights, property or safety of Hype.News, its users
              and the public.
            </p>
            <br>
            <h4>Rules</h4>
            <p>
              You must not do any of the following while accessing or using the Service: (i) use the Service for any
              unlawful purposes or for promotion of illegal activities; (ii) upload or post any Content (as defined
              above) in violation of the provisions contained in the "Your License to Hype.News" section of these terms;
              (iii) use the Service for the purpose of spamming anyone; (iv) access or tamper with non-public areas of
              the Service, Hype.News' computer systems, or the technical delivery systems of Hype.News’ providers; (v)
              probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or
              authentication measures; (vi) access or search or attempt to access or search the Service by any means
              (automated or otherwise) other than through the currently available, published interfaces that are
              provided by Hype.News unless you have been specifically allowed to do so in a separate agreement with
              Hype.News (crawling the Service is permissible in accordance with these Terms, but scraping the Service
              without the prior consent of Hype.News except as permitted by these Terms is expressly prohibited); (vii)
              forge any TCP/IP packet header or any part of the header information in any email or posting, or in any
              way use the Service to send altered, deceptive or false source-identifying information; or (viii)
              interfere with or disrupt (or attempt to do so) the access of any user, host or network, including,
              without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Service, or by
              scripting the creation of Content in such a manner as to interfere with or create an undue burden on the
              Service.
            </p>
            <br>
            <h4>Links</h4>
            <p>
              Our Service may contain links to third-party websites or services that are not owned or controlled by us.
              Hype.News/Hypefactors has no control over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third party web sites or services. You further acknowledge and agree that
              Hype.News/ Hypefactors shall not be responsible or liable, directly or indirectly, for any damage or loss
              caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
              services available on or through any such web sites or services.
            </p>
            <br>
            <h4>Termination</h4>
            <p>
              We may terminate or suspend your account immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the
              Service will immediately cease. If you wish to terminate your account, you may simply discontinue using
              the Service.
            </p>
            <br>
            <h4>Limitation Of Liability</h4>
            <p>
              In no event shall Hype.News/Hypefactors, nor its directors, employees, partners, agents, suppliers, or
              affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including
              without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i)
              your access to or use of or inability to access or use the Service; (ii) any conduct or content of any
              third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use
              or alteration of your transmissions or content, whether based on warranty, contract, tort (including
              negligence) or any other legal theory, whether or not we have been informed of the possibility of such
              damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
            </p>
            <h4>Proprietary Rights</h4>
            <p>
              All right, title, and interest in and to the Service (excluding Content provided by users) are and will
              remain the exclusive property of Hype.News and its licensors. Except as expressly provided herein, nothing
              in the Terms gives you a right to use the Hype.News or Hypefactors name or any of the Hype.News or
              Hypefactors trademarks, logos, domain names, and other distinctive brand features.
            </p>
            <br>
            <h4>Disclaimer</h4>
            <p>
              Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
              basis. The Service is provided without warranties of any kind, whether express or implied, including, but
              not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement
              or course of performance. Hypefactors, its subsidiaries, affiliates, and its licensors do not warrant that
              a) the Service will function uninterrupted, secure or available at any particular time or location; b) any
              errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d)
              the results of using the Service will meet your requirements.
            </p>
            <br>
            <h4>Governing Law</h4>
            <p>
              These Terms shall be governed and construed in accordance with the laws of Denmark, without regard to its
              conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be
              considered a waiver of those rights. If any provision of these Terms is held to be invalid or
              unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms
              constitute the entire agreement between us regarding our Service, and supersede and replace any prior
              agreements we might have between us regarding the Service.
            </p>
            <br>
            <h4>Changes</h4>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
              is material we will try to provide at least 30 days notice prior to any new terms taking effect. What
              constitutes a material change will be determined at our sole discretion.
            </p>
            <p>
              By continuing to access or use our Service after those revisions become effective, you agree to be bound
              by the revised terms. If you do not agree to the new terms, please stop using the Service.
            </p>
            <br>
            <h4>Contact Us</h4>
            <p>
              If you have any questions about these Terms, please contact us on info@hypefactors.com
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {}
</script>
